import { initializeApp } from 'firebase/app';

let config = {
    apiKey: "AIzaSyAQMiZ8f8zd0TYxOXsLHctJNTN6SO-nvCc",
    authDomain: "ashleys-party.firebaseapp.com",
    databaseURL: "https://ashleys-party-default-rtdb.firebaseio.com",
    projectId: "ashleys-party",
    storageBucket: "ashleys-party.appspot.com",
    messagingSenderId: "647467436170",
    appId: "1:647467436170:web:a14aa6d988cb4ad3994573",
    measurementId: "G-38T83Q0Z84"
};

const firebase = initializeApp(config);

export default firebase;