import Vue from 'vue'
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";
import { LoadingProgrammatic as Loading } from 'buefy/src/components/loading'

export function loadRemoteConfig() {
    const app = Vue.prototype.$firebaseApp
    const remoteConfig = getRemoteConfig(app);

    return new Promise((resolve, reject) => {
        remoteConfig.settings.fetchTimeoutMillis = 10000;
        remoteConfig.settings.minimumFetchIntervalMillis = 1;

        fetchAndActivate(remoteConfig).then(() => {
            console.log('Remote config fetched and activated');
            resolve()
        });

        Vue.prototype.$remoteConfig = remoteConfig;
    });
}

export function getRemoteConfigValue(key) {
    const remoteConfig = Vue.prototype.$remoteConfig;

    return new Promise((resolve, reject) => {
        const value = getValue(remoteConfig, key);
        if (value !== undefined || value !== null || value !== '') {
            resolve(value);
        } else {
            reject();
        }
    });
}