import Vue from 'vue'
import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'

export function loadUser() {
  const app = Vue.prototype.$firebaseApp
  const auth = getAuth(app)
  const db = getFirestore(app)

  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async function(user) {
      if (user) {
        const userRef = doc(db, `users/${user.uid}`)
        const userDoc = await getDoc(userRef)

        if (!userDoc.exists) {
          reject(new Error('User not found'))
        }

        const userData = {
          ...userDoc.data(),
          authUser: user
        }

        resolve(userData)
      } else {
        reject(new Error('User not found'))
      }
    })
  })
}

export async function getAvatar() {
  const app = Vue.prototype.$firebaseApp
  const auth = getAuth(app)
  const storage = getStorage(app)

  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async function(user) {
      if (user) {

        const avatarRef = ref(storage, `users/${user.uid}/profile-picture/maxresdefault.jpg`)

        getDownloadURL(avatarRef).then(onResolve, onReject);

        async function onResolve(avatarUrl) {
          const response = await fetch(avatarUrl)

          if (!response.ok) {
            reject('User avatar not found')
          }

          resolve(response.blob().then((blob) => { return URL.createObjectURL(blob) }))
        }

        function onReject(error) {
          reject('User avatar not found')
        }

      } else {
        reject('User avatar not found')
      }
    })
  })
}


// const app = Vue.prototype.$firebaseApp;
// const auth = getAuth(app);
// const db = getFirestore(app);
// const storage = getStorage();

// export async function loadUser(thisite) {
//     return new Promise((resolve, reject) => {

//         onAuthStateChanged(auth, async user => {
//             if (user) {
//                 enableIndexedDbPersistence(db);

//                 const userRef = doc(db, 'users', user.uid);
//                 const userDoc = await getDoc(userRef);

//                 if (!userDoc.exists) {
//                     reject(new Error('User not found'));
//                 }

//                 const userData = {
//                     ...userDoc.data(),
//                     authUser: user
//                 }

//                 resolve(userData);

//             } else {
//                 reject(new Error('User not found'));
//             }
//         });
//     });
// }

// export async function loadAuthUser() {
//     return new Promise((resolve, reject) => {
//         onAuthStateChanged(auth, async user => {
//             if (user) {
//                 resolve(user);
//             } else {
//                 reject(new Error('User not found'));
//             }
//         });
//     });
// }

// export async function getProfilePictureURL(uid) {
//     const url = await getDownloadURL(ref(storage, 'users/' + uid + '/profile-picture/maxresdefault.jpg'));
//     return url;
// }

// export async function convertToBlob(url) {
//     const response = await fetch(url);

//     if (!response.ok) {
//         throw new Error('Image data Error');
//     }

//     return response.blob().then((blob) => { return URL.createObjectURL(blob) });
// }
