export async function handleFetchErrors(response) {
    if (!response.ok) {
        console.log('error');
        const JSON = await response.json();

        if (JSON.error.message) {
            throw Error(JSON.error.message);
        }

        throw Error("Error loading categories");
    }

    return response;
}