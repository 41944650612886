import Vue from "vue";
import Vuex from "vuex";
import router from "./router";

import { ToastProgrammatic as Toast } from "buefy";

import { loadUser, getAvatar } from "./assets/js/userController";
import { getAuth, signInWithEmailAndPassword, signOut, updatePassword, onAuthStateChanged, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { handleFetchErrors } from './helpers/errorHandlers';
import { getRemoteConfigValue } from './assets/js/remoteConfig';

Vue.use(Vuex);

const site = {
    state: {
        user: null,
        avatar: null,
        cards: [],
        featuredMenu: null
    },
    actions: {
        async login({ commit }, user) {
            const app = Vue.prototype.$firebaseApp
            const auth = getAuth(app)

            const email = user.email;
            const password = user.password;

            return new Promise((resolve, reject) => {
                signInWithEmailAndPassword(auth, email, password)
                    .then((userCredentials) => {
                        const user = userCredentials.user

                        user.getIdToken().then(idToken => {
                            const d = new Date();
                            d.setTime(d.getTime() + (3600 * 1000));
                            let expires = d.toUTCString();

                            document.cookie = "login=" + idToken + "; expires=" + expires + "; path=/";
                        });

                        resolve(user);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async changePassword({ commit }, credentials) {
            const app = Vue.prototype.$firebaseApp
            const auth = getAuth(app)

            const oldPassword = credentials.oldPassword;
            const newPassword = credentials.newPassword;

            return new Promise((resolve, reject) => {
                onAuthStateChanged(auth, async function(user) {
                    if (user) {

                        const creds = EmailAuthProvider.credential(user.email, oldPassword)
                        reauthenticateWithCredential(user, creds).then(() => {

                            updatePassword(user, newPassword).then(() => {
                                resolve(user);
                            }).catch((error) => reject(error));

                        }).catch((error) => reject(error));

                    } else {
                        reject('User not found, try logging in first');
                    }
                });
            });
        },
        async signOut({ commit }) {
            const app = Vue.prototype.$firebaseApp
            const auth = getAuth(app)

            return new Promise((resolve, reject) => {
                signOut(auth)
                    .then(() => {
                        const d = new Date();
                        let expires = d.toUTCString();
                        document.cookie = "login=; expires=" + expires + "; path=/";
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async loadUser({ commit }) {
            try {
                const user = await loadUser();
                commit("setUser", user);
            } catch (error) {
                Toast.open({
                    message: error.message,
                    type: "is-danger",
                    queue: false,
                    duration: 3000
                });
            }
        },
        // async loadAvatar({ commit }) {
        //     try {
        //         const avatar = await getAvatar();
        //         commit("setAvatar", avatar);
        //     } catch (error) {
        //         Toast.open({
        //             message: error.message,
        //             type: "is-danger",
        //             queue: false,
        //             duration: 3000
        //         });
        //     }
        // },
        async loadCards({ commit }, payload) {

            const apiURL = await getRemoteConfigValue('APIurl');

            const app = Vue.prototype.$firebaseApp
            const auth = getAuth(app)
            const token = await auth.currentUser.getIdToken();

            const customerID = payload.customerID;
            const cards = await fetch(`${apiURL.asString()}/api/cards/${customerID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    "csrf-token": Vue.$cookies.get('XSRF-TOKEN'),
                },
            });

            if (cards.status == 200) {
                const cardsJSON = await cards.json();
                return cardsJSON;
            }

            const error = await cards.json();

            Toast.open({
                message: "Error loading cards: <br>" + error.error.message,
                type: "is-danger",
                queue: false,
                duration: 10000
            });

            router.push('/account');

        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setAvatar(state, avatar) {
            state.avatar = avatar;
        },
        setCards(state, cards) {
            state.cards = cards;
        },
        setFeaturedMenu(state, featuredMenu) {
            state.featuredMenu = featuredMenu;
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        avatar(state) {
            return state.avatar;
        },
        cards(state) {
            return state.cards;
        },
        featuredMenu(state) {
            return state.featuredMenu;
        }
    },
    beforeMount() {

    }
};

const order = {
    actions: {
        async getCategoryName({ commit }, categoryID) {

            const apiURL = await getRemoteConfigValue('APIurl');

            const response = await fetch(`${apiURL.asString()}/api/products/${categoryID}/get`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "csrf-token": Vue.$cookies.get('XSRF-TOKEN'),
                }
            }).then(handleFetchErrors);

            const category = await response.json();
            const object = category.object;

            if (object.category_data.name) {
                return object.category_data.name;
            }

            return "";
        },
        async getCategoriesList({ commit }) {

            const apiURL = await getRemoteConfigValue('APIurl');

            const response = await fetch(`${apiURL.asString()}/api/products/category/list`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "csrf-token": Vue.$cookies.get('XSRF-TOKEN'),
                }
            }).then(handleFetchErrors);

            const categoriesList = await response.json();
            const objects = categoriesList.objects;

            return objects;
        },
        async getItemsList({ commit }, categoryID) {

            const apiURL = await getRemoteConfigValue('APIurl');

            const response = await fetch(`${apiURL.asString()}/api/products/${categoryID}/list`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "csrf-token": Vue.$cookies.get('XSRF-TOKEN'),
                }
            }).then(handleFetchErrors);

            const itemsList = await response.json();
            const objects = itemsList.items;

            return objects;
        },
        async getItem({ commit }, payload) {

            const apiURL = await getRemoteConfigValue('APIurl');

            const itemID = payload.id;

            const response = await fetch(`${apiURL.asString()}/api/products/${itemID}/get`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "csrf-token": Vue.$cookies.get('XSRF-TOKEN'),
                }
            });

            if (!response.ok) {
                console.log(response);
                return;
            }

            const item = await response.json();
            const object = item.object;

            return object;
        }
    }
}

const cart = {
    state: {
        shoppingCart: [],
    },
    mutations: {
        setCart(state, cart) {
            state.shoppingCart = cart;
            localStorage.setItem('shoppingCart', JSON.stringify(cart));
        },
    },
    getters: {
        getCart(state) {
            return state.shoppingCart;
        },
    }
};

const account = {
    state: {
        user: {},
        orders: {},
    },
    actions: {
        // async getOrders({ commit }, payload) {
        //     const page = payload.page;
        //     const pageSize = payload.pageSize;
        //     const lastId = payload.lastId;

        //     const orders = await loadOrders(page, pageSize, lastId).catch(error => console.log(error));
        //     console.log(orders);
        //     return orders;
        // }
    },
    mutations: {
    },
    getters: {
    }
}

const store = new Vuex.Store({
    modules: {
        site,
        cart,
        order,
        account,
    }
});

export default store;