import Vue from "vue";
import Router from 'vue-router'
import store from "./store";

const HomePage = { template: '<App/>', components: { App: () => import('./views/Home/Home.vue') } };
const PageNotFound = { template: '<App/>', components: { App: () => import('./components/404.vue') } };

const EventsMainPage = { template: '<App/>', components: { App: () => import('./views/Events/EventsMain.vue') } };
const EventsMenuPage = { template: '<App/>', components: { App: () => import('./views/Events/EventsMenu.vue') } };
const EventsViewPage = { template: '<App/>', components: { App: () => import('./views/Events/EventsView.vue') } };
const EventsPayPage = { template: '<App/>', components: { App: () => import('./views/Events/EventsPay.vue') } };

const AccountMainPage = { template: '<App/>', components: { App: () => import('./views/Account/AccountMain.vue') } };
const AccountMenuPage = { template: '<App/>', components: { App: () => import('./views/Account/AccountMenu.vue') } };

const AccountDetailsMainPage = { template: '<App/>', components: { App: () => import('./views/Account/Details/AccountDetailsMain.vue') } };
const AccountDetailsEditPage = { template: '<App/>', components: { App: () => import('./views/Account/Details/AccountDetailsEdit.vue') } };

const AccountLoginMainPage = { template: '<App/>', components: { App: () => import('./views/Account/Login/AccountLoginMain.vue') } };
const AccountLoginEditPage = { template: '<App/>', components: { App: () => import('./views/Account/Login/AccountLoginEdit.vue') } };

const AccountCardMainPage = { template: '<App/>', components: { App: () => import('./views/Account/Card/AccountCardMain.vue') } };
const AccountCardListPage = { template: '<App/>', components: { App: () => import('./views/Account/Card/AccountCardList.vue') } };
const AccountCardAddPage = { template: '<App/>', components: { App: () => import('./views/Account/Card/AccountCardAdd.vue') } };

const AccountOrdersMainPage = { template: '<App/>', components: { App: () => import('./views/Account/Orders/AccountOrdersMain.vue') } };
const AccountOrdersListPage = { template: '<App/>', components: { App: () => import("./views/Account/Orders/AccountOrdersList.vue") } };
const AccountOrdersViewPage = { template: '<App/>', components: { App: () => import("./views/Account/Orders/AccountOrdersView.vue") } };

const AccountTransactionsMainPage = { template: '<App/>', components: { App: () => import('./views/Account/Transactions/AccountTransactionsMain.vue') } };
const AccountTransactionsListPage = { template: '<App/>', components: { App: () => import("./views/Account/Transactions/AccountTransactionsList.vue") } };
const AccountTransactionsViewPage = { template: '<App/>', components: { App: () => import("./views/Account/Transactions/AccountTransactionsView.vue") } };

const AccountHelpMainPage = { template: '<App/>', components: { App: () => import('./views/Account/Help/AccountHelpMain.vue') } };
const AccountHelpButtonsPage = { template: '<App/>', components: { App: () => import('./views/Account/Help/AccountHelpButtons.vue') } };


const OrderMainPage = { template: '<App/>', components: { App: () => import("./views/Order/OrderMain.vue") } };

const OrderMenuMainPage = { template: '<App/>', components: { App: () => import("./views/Order/Menu/OrderMenuMain.vue") } };
const OrderPayMainPage = { template: '<App/>', components: { App: () => import("./views/Order/Pay/OrderPayMain.vue") } };

Vue.use(Router);

const home = {
    name: 'home',
    path: '/home', alias: '',
    component: HomePage
};

const order = {
    path: '/order',
    redirect: '/order/menu',
    component: OrderMainPage,
    children: [
        {
            name: 'order-menu',
            path: 'menu',
            component: OrderMenuMainPage,
            children: [
                { name: 'order-menu-categories', path: '', component: () => import('./views/Order/Menu/OrderMenuCategories.vue') },
                { name: 'order-menu-items', path: 'category/:id', component: () => import('./views/Order/Menu/OrderMenuItems.vue') },
                { name: 'order-menu-variation', path: 'item/:id', component: () => import('./views/Order/Menu/OrderMenuVariation.vue') },
            ]
        },
        {
            name: 'order-pay',
            path: 'pay',
            component: OrderPayMainPage,
            children: [
                { name: 'order-pay-details', path: '', component: () => import('./views/Order/Pay/OrderPayDetails.vue') },
                { name: 'order-pay-confirm', path: 'confirm', component: () => import('./views/Order/Pay/OrderPayConfirm.vue') },
            ]
        }
    ]
}

const events = {
    name: 'events',
    path: '/events',
    component: EventsMainPage,
    children: [
        { name: 'events', path: '', component: EventsMenuPage },
        { name: 'events', path: 'pay/:id', component: EventsPayPage },
        { name: 'events', path: ':id', component: EventsViewPage },
    ]
}

const account = {
    name: 'account',
    path: '/account',
    component: AccountMainPage,
    children: [
        { name: 'account-menu', path: '', component: AccountMenuPage },
        {
            name: 'details-main',
            path: 'details',
            component: AccountDetailsMainPage,
            children: [
                { name: 'details-edit', path: '', component: AccountDetailsEditPage }
            ]
        },
        {
            name: 'login-main',
            path: 'login',
            component: AccountLoginMainPage,
            children: [
                { name: 'login-edit', path: '', component: AccountLoginEditPage }
            ]
        },
        {
            name: 'card-main',
            path: 'cards',
            component: AccountCardMainPage,
            children: [
                { name: 'card-list', path: '', component: AccountCardListPage },
                { name: 'card-add', path: 'add', component: AccountCardAddPage }
            ]
        },
        {
            name: 'orders-main',
            path: 'orders',
            component: AccountOrdersMainPage,
            children: [
                { name: 'orders-list', path: '', component: AccountOrdersListPage },
                { name: 'orders-view', path: ':id', component: AccountOrdersViewPage },
            ]
        },
        {
            name: 'transactions-main',
            path: 'transactions',
            component: AccountTransactionsMainPage,
            children: [
                { name: 'transactions-list', path: '', component: AccountTransactionsListPage },
                { name: 'transactions-view', path: ':id', component: AccountTransactionsViewPage },
            ]
        },
        {
            name: 'help-main',
            path: 'help',
            component: AccountHelpMainPage,
            children: [
                { name: 'help-buttons', path: '', component: AccountHelpButtonsPage }
            ]
        },
    ]
}

const notfound = {
    name: 'notfound',
    path: '/:catchAll(.*)*',
    component: PageNotFound
};

const routes = [
    home,
    order,
    events,
    account,
    notfound
];

const router = new Router({
    mode: 'history',
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});


export default router;